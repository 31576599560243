import icons from '../table/icons';
import React, { useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import { capitalize, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FormatDate from '../../../helpers/formatDate';

export default function UserTransationTable({ getUsers, comfirmAction }) {
  return (
    <div classaction='table-wrap border styleTable'>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          {
            title: 'Email (filterable)',
            field: 'email',
          },

          { title: 'Name', field: 'name' },

          { title: 'Phone', field: 'phone' },

          {
            title: 'Last Login (filterable)',
            field: 'lastLogin',
          },
          {
            title: 'User created on (filterable)',
            field: 'createdOn',
          },
          { title: 'Status', field: 'status' },
          { title: 'Action', field: 'action' },
        ]}
        data={getUsers?.map((data) => {
          return {
            email: capitalize(data.email),
            name: (
              <Link to={`edit_user/${data.id}`}>
                {' '}
                {`${capitalize(data.lastName)} ${capitalize(data.firstName)}`}
              </Link>
            ),
            phone: data.phoneNumber,
            lastLogin: FormatDate(data.lastLoggedIn),
            createdOn: FormatDate(data.createdAt),
            status: capitalize(data.status),
            action: (
              <div>
                <select
                  className='useractionSelect'
                  name=''
                  onChange={(e) => {
                    comfirmAction(e.target.value, data.id);
                  }}
                  id=''
                >
                  <option value=''>Select Action</option>
                  <option value='suspend'>Suspend</option>
                  <option value='inactivate'>Deactivate</option>
                  <option value='active'>Activate</option>
                </select>
              </div>
            ),
          };
        })}
        title={`User Management`}
      />
    </div>
  );
}
