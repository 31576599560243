import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import VendorTable from "./Table";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet, httpPost } from "../../../action/http";
import swal from "sweetalert";
import { hideLoader, showLoader } from "../../../utils/loader";

const Index = (props) => {
  const [vendors, setVendors] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState("create");
  let [GroupId, setGroupId] = useState();
  const [loading, setLoading] = useState(true);

  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  useEffect(() => {
    getAllVendors();
    if (getDeletDetails.reload_state === true) {
      getAllVendors();
    }
  }, [getDeletDetails.reload_state === true]);

  const getAllVendors = async () => {
    try {
      const res = await httpGet("/auth/all_vendors");
      if (res.code === 200) {
        setVendors(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };

  const PerformUserAction = async (value, id) => {
    showLoader();
    try {
      const res = await httpPost(
        `/auth/update_user_status?status=${value}&userId=${id}`
      );
      if (res.code === 200) {
        swal("Changed!", "User status changed!", "success");
        getAllVendors();
        hideLoader(false);
      }
    } catch (error) {
      hideLoader();
      return;
    }
  };

  const comfirmAction = async (value, id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you wish to perform this action?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        PerformUserAction(value, id);
        // swal("Deleted!", "Your imaginary file has been deleted!", "success");
      }
    });
  };

  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="vendor_mgt"
        activepage="keepOpenGroup"
      >
        {loading ? (
          <div className="row h-100 justify-content-center align-items-center">
            <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
          </div>
        ) : (
          <div className="table-wrap">
            <div>
              <button
                className="create-ntn654ty"
                onClick={() => props.history.push("/create_vendor")}
              >
                Create new vendor
              </button>
            </div>

            <VendorTable
              getVendors={vendors}
              GetEditDataModals={GetEditDataModals}
              comfirmAction={comfirmAction}
            />
          </div>
        )}
      </Layout>
      <ConfirmModal />
      {/* <CreateGroupModal
        modalEdit={modalEdit}
        getAllGroups={getAllGroups}
        modalType={modalType}
        setmodalType={setmodalType}
        GroupId={GroupId}
      /> */}
    </div>
  );
};

export default Index;
