import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import UserTable from "./Table";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet, httpPost } from "../../../action/http";
import swal from "sweetalert";
import { httpPatch } from "./../../../action/http";
import { hideLoader, showLoader } from "../../../utils/loader";

const Index = (props) => {
  const [users, setUsers] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState("create");
  let [GroupId, setGroupId] = useState();
  const [loading, setLoading] = useState(true);

  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  useEffect(() => {
    getUsers();
    if (getDeletDetails.reload_state === true) {
      getUsers();
    }
  }, [getDeletDetails.reload_state === true]);

  const getUsers = async () => {
    try {
      const res = await httpGet("/auth/all_users");
      if (res.code === 200) {
        setUsers(res.data);
        setLoading(false);
      }
    } catch (error) {
      return;
    }
  };

  const PerformUserAction = async (value, id) => {
    showLoader();
    try {
      const res = await httpPost(
        `/auth/update_user_status?status=${value}&userId=${id}`
      );
      if (res.code === 200) {
        swal("Changed!", "User status changed!", "success");
        getUsers();
        hideLoader(false);
      }
    } catch (error) {
      hideLoader();
      return;
    }
  };

  const comfirmAction = async (value, id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you wish to perform this action?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        PerformUserAction(value, id);
        // swal("Deleted!", "Your imaginary file has been deleted!", "success");
      }
    });
  };
  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="user_mgt"
        activepage="keepOpenGroup"
      >
        <div className="table-wrap">
          <div>
            <button
              className="create-ntn654ty"
              onClick={() => props.history.push("/create_user")}
            >
              Create new user
            </button>
          </div>

          <UserTable
            getUsers={users || []}
            GetEditDataModals={GetEditDataModals}
            comfirmAction={comfirmAction}
          />
        </div>
      </Layout>
      <ConfirmModal />
      {/* <CreateGroupModal
        modalEdit={modalEdit}
        getUsers={getUsers}
        modalType={modalType}
        setmodalType={setmodalType}
        GroupId={GroupId}
      /> */}
    </div>
  );
};

export default Index;
