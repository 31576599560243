import React from 'react';
import icons from '../table/icons';
import MaterialTable from 'material-table';
import { Paper } from '@material-ui/core';
import FormatDate from '../../../helpers/formatDate';

export const SubscriptionTable = (props) => {
  return (
    <div classaction='table-wrap border styleTable'>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        columns={[
          {
            title: 'Customer Id',
            field: 'customerId',
          },
          {
            title: 'Customer Name',
            field: 'customerName',
          },
          {
            title: 'Amount',
            field: 'amount',
          },
          {
            title: 'Vend Amount',
            field: 'vendAmount',
          },
          { title: 'Reference', field: 'reference' },
          { title: 'Package Slug', field: 'packageSlug' },
          { title: 'Type', field: 'type' },
          {
            title: 'Status',
            field: 'status',
          },
          { title: 'Date', field: 'date' },
          { title: 'Action', field: 'action' },
        ]}
        data={props.getSubscriptions.map((data) => {
          return {
            customerId: data.customerId,
            customerName: `${data?.user?.lastName} ${data?.user?.firstName}`,
            amount: ` ₦${data.amount}`,
            vendAmount: ` ₦${data.vendAmount}`,
            status: data.status,
            reference: data.reference,
            packageSlug: data.packageSlug,
            type: data.type,
            date: FormatDate(data.createdAt),
            action: (
              <div>
                <select
                  name=''
                  id=''
                  className='form-control'
                  disabled={
                    data.status !== 'Failed to process' &&
                    data.status !== 'Request in progress'
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    props.approveSubscription(
                      data.status,
                      data.reference,
                      e.target.value,
                      data.transactionId
                    )
                  }
                >
                  <option value='' disabled selected>
                    Select
                  </option>
                  <option value='withB'>Retry with B</option>
                  <option value='withoutB'>Retry without B</option>
                </select>
                {/* <button
                  className='btn button-primary text-white mr-3'
                  disabled={data.status !== 'Failed to process' ? true : false}
                  style={
                    data.status !== 'Failed to process'
                      ? { opacity: '0.5' }
                      : {}
                  }
                  onClick={() =>
                    props.approveSubscription(data.status, data.id)
                  }
                >
                  Retry B
                </button>
                <button
                  className='btn button-primary text-white mr-3'
                  disabled={data.status !== 'Failed to process' ? true : false}
                  style={
                    data.status !== 'Failed to process'
                      ? { opacity: '0.5' }
                      : {}
                  }
                  onClick={() =>
                    props.approveSubscription(data.status, data.id)
                  }
                >
                  Retry - B
                </button> */}
              </div>
            ),
          };
        })}
        title='Vat History'
      />
    </div>
  );
};
