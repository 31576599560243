import React, { useState } from "react";
import { Link } from "react-router-dom";
import icons from "../table/icons";
import MaterialTable, { Column } from "material-table";
import { capitalize, Paper } from "@material-ui/core";
import FormatDate from "../../../helpers/formatDate";

export default function UserTransationTable({
  getVendors,
  GetEditDataModals,
  comfirmAction,
}) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          {
            title: "Company Name (filterable)",
            field: "companyName",
          },
          {
            title: "Email (filterable)",
            field: "email",
          },

          { title: "Name", field: "name", sortable: true, filterable: true },

          { title: "Phone", field: "phone" },
          { title: "Rc Number", field: "rcNumber" },

          {
            title: "Last Login (filterable)",
            field: "lastLogin",
          },
          {
            title: "Vendor created on (filterable)",
            field: "createdOn",
          },
          { title: "Status", field: "status" },

          { title: "Action", field: "action" },
        ]}
        data={getVendors?.map((data) => {
          return {
            companyName: (
              <Link to={`view_vendor/${data.id}`}>
                {data.companyName === null
                  ? "No data added"
                  : capitalize(data.companyName)}
              </Link>
            ),
            email: capitalize(data.email),
            name: `${capitalize(data.lastName)} ${capitalize(data.firstName)}`,
            phone: capitalize(data.phoneNumber),
            rcNumber:
              data?.rcNumber === null
                ? "Rc Number not added"
                : capitalize(data?.rcNumber),
            lastLogin: FormatDate(data.lastLoggedIn),
            createdOn: FormatDate(data.createdAt),
            status: capitalize(data.status),
            action: (
              <div style={{ display: "flex" }}>
                <Link to={`edit_vendor/${data.id}`}>
                  <button
                    className="btn btn-success text-white mr-3"
                    // onClick={() => props.updateStatus('active', data.id)}
                  >
                    Edit
                  </button>
                </Link>

                <select
                  className="useractionSelect"
                  name=""
                  onChange={(e) => {
                    comfirmAction(e.target.value, data.id);
                  }}
                  id=""
                >
                  <option value="">Select Action</option>
                  <option value="suspend">Suspend</option>
                  <option value="inactivate">Deactivate</option>
                  <option value="activate">Activate</option>
                </select>
              </div>
            ),
          };
        })}
        title={`Vendor Management`}
      />
    </div>
  );
}
