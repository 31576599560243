import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useRecoilState } from 'recoil';
import swal from 'sweetalert';
import Layout from '../../shared/Layout';
import { SubscriptionTable } from './Table';
import { ConfirmModal } from '../../shared/Modal/ConfirmModal';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import { httpGet, httpPost } from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';
//import CreateGroupModal from '../Modals/createGroup';

const Index = (props) => {
  const [subscription, setSubscription] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState('create');
  let [GroupId, setGroupId] = useState();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  useEffect(() => {
    getAllPaymentHistory();
    if (getDeletDetails.reload_state === true) {
      getAllPaymentHistory();
    }
  }, [getDeletDetails.reload_state === true]);

  const getAllPaymentHistory = async () => {
    try {
      const res = await httpGet('/admin/bill_payment_history');
      if (res.code === 200) {
        setSubscription(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };

  const approveSubscription = async (
    status,
    reference,
    value,
    transactionId
  ) => {
    const willDisable = await swal({
      title: 'Are you sure?',
      text: `Are you sure that you want to retry?`,
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    });

    if (willDisable) {
      showLoader();

      let res;
      if (value === 'withB') {
        res = await httpPost(
          `/manual_retry?reference=${reference}&transactionId=${transactionId}`,
          {}
        );
      } else {
        res = await httpPost(
          `/manual_retry_withoutB?reference=${reference}&transactionId=${transactionId}`,
          {}
        );
      }
      if (res.code === 200) {
        hideLoader();
        swal('Done', res.message, 'success').then(() => getAllPaymentHistory());
      } else {
        swal('error', res.message, 'fail');
      }
    }
  };

  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page='vat_fee_history'
        activepage='keepOpenGroup'
      >
        {loading ? (
          <div className='row h-100 justify-content-center align-items-center'>
            <Loader type='TailSpin' color='#EF6C00' height={50} width={50} />
          </div>
        ) : (
          <div className='table-wrap'>
            {/* <GroupsTable getGroup={getGroup}/> */}
            <SubscriptionTable
              getSubscriptions={subscription || []}
              GetEditDataModals={GetEditDataModals}
              approveSubscription={approveSubscription}
            />
          </div>
        )}
      </Layout>
      <ConfirmModal />
      {/* <CreateGroupModal
        modalEdit={modalEdit}
        getAllSubscription={getAllSubscription}
        modalType={modalType}
        setmodalType={setmodalType}
        GroupId={GroupId}
      /> */}
    </div>
  );
};

export default Index;
