import React, { useState } from 'react';
import swal from 'sweetalert';
import validator from 'validator';
import { httpPost } from '../../../action/http';
import Input from '../../shared/Input';
import Button from '../../shared/Button';
import { NotificationManager } from 'react-notifications';
import logo from '../../../assets/image/logo1.png';

const Index = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validator.isEmpty(password)) {
      swal('error', 'Password cannot be empty', 'Error');
      return;
    }
    if (validator.isEmpty(email)) {
      swal('error', 'Email cannot be empty', 'Error');
      return;
    }
    const data = {
      email,
      password,
    };
    const res = await httpPost('/auth/login', data);
    console.log(res);
    if (res.code === 200) {
      NotificationManager.success(res.message, 'Success', 3000);
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', JSON.stringify(res.data.user));
      props.history.push('/dashboard');
    } else {
      swal('Opps!', res.er.message, 'error');
      console.log(res);
      setLoading(false);
    }
  };
  return (
    <div className="row h-100">
      <div className="col-md-6 mx-auto my-auto h-50 text-center p-0">
        <img src={logo} alt="logo" />
        <h4 className="mt-5">Login</h4>
        <form autocomplete="off">
          <div className="p-xs-5">
            <Input
              type="text"
              placeholder="Email"
              className="form-control col-md-6 col-sm-6 mx-auto"
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <Input
              type="password"
              placeholder="Password"
              className="form-control col-md-6 col-sm-6 mx-auto"
              onChange={(e) => setPassword(e.target.value)}
            />

            <a
              href="#"
              className="w-100 col-md-6 col-sm-6 mx-auto d-block text-left pl-0 mt-4"
              onClick={() => props.history.push('/forgot_password')}
            >
              Forgot Password?
            </a>

            <Button
              className="form-control col-md-6 col-sm-6 mx-auto mt-2"
              type="submit"
              onClick={handleLogin}
              loading={loading}
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
